'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.devices.factory:DeviceInterfacesManager

 # @description

###
angular
  .module 'mundoAdmin.devices'
  .factory 'DeviceInterfacesManager', [
    '$mdDialog'
    'Restangular'
    'DeviceInterfaceTypeManager'
    '_'
    'MundoDefaultManager'
    (
      $mdDialog
      Restangular
      DeviceInterfaceTypeManager
      _
      MundoDefaultManager
    )->
      DeviceInterfacesManagerBase = new MundoDefaultManager()
      DeviceInterfacesManagerBase.setUrl('interfaces')
      DeviceInterfacesManagerBase.setNewObject(['code'])
      DeviceInterfacesManagerBase.setParentUrl('devices')


      DeviceInterfacesManagerBase.one = (id) ->
        Restangular.one(DeviceInterfacesManagerBase.getParentUrl(), DeviceInterfacesManagerBase.getParentId()).get()
        .then((data) ->
          deviceInterface = _.first(_.filter(data.deviceInterfaces, 'id', id))
          deviceInterface
        )

      DeviceInterfacesManagerBase.getEditForm = (data) ->
        form = DeviceInterfaceTypeManager.getFormByType(data.type, data)
        DeviceInterfacesManagerBase.setUpdateObject(DeviceInterfaceTypeManager.getObject(data.type))
        form

      DeviceInterfacesManagerBase
  ]
